import React from 'react';
export const Paginator = (props) => {
    function clickHandler(e) {
        e.preventDefault();
        const target = e.target;
        if (target.dataset.page) {
            props.updatePage(parseInt(target.dataset.page));
        }
    }
    let current = props.page;
    let start = 1;
    let end = props.pagination.totalPages;
    if (end - current < 2 && end > 5) {
        start = end - 4;
    }
    else if (current > 3) {
        start = current - 2;
        end = end > (current + 2) ? current + 2 : end;
    }
    else {
        end = Math.min(end, 5);
    }
    let prev = Math.max(1, (current - 1));
    let next = Math.min(end, (current + 1));
    let range = [];
    for (let i = start; i <= end; i++) {
        range.push(React.createElement("a", { href: `?page=${i}`, "data-page": i, key: i, className: "hidden md:block " + (current === i ? 'font-bold' : '') }, i));
    }
    return (React.createElement("div", { id: "paginator", className: "min-w-[220px]", onClick: clickHandler },
        React.createElement("div", { className: "pages flex justify-between" },
            range,
            React.createElement("a", { href: `?page=${prev}`, "data-page": prev, "aria-label": "Prev page", className: (prev === 1 ? 'opacity-50' : '') }, "\u276E"),
            React.createElement("div", { className: "md:hidden" },
                "Page ",
                current),
            React.createElement("a", { href: `?page=${next}`, "data-page": next, "aria-label": "last page", className: (current === end ? 'opacity-50' : '') }, "\u276F"))));
};
export const ResultCount = (props) => {
    return (React.createElement("div", { className: "result-count " + props.className },
        props.pagination.first,
        "-",
        props.pagination.last,
        " of ",
        props.pagination.entryTotal,
        " results"));
};
