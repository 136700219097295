import React from 'react';
export const UseSearch = (props) => {
    return (React.createElement("div", null,
        React.createElement("a", { href: '#', onClick: props.onClick, "aria-label": "Open Search" },
            React.createElement("img", { src: "/assets/images/icons/Search.svg", alt: "Open Search" }))));
};
export const SearchForm = (props) => {
    function submitQuery(e) {
        e.preventDefault();
        const target = e.target;
        props.updateQuery(target.query.value);
    }
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("a", { href: '#', onClick: props.closeSearch, className: 'mb-2 flex items-center gap-2', "aria-label": "Close Search" },
                React.createElement("img", { src: "/assets/images/icons/arrow-left.svg", alt: "" }),
                "Back To Insights")),
        React.createElement("form", { onSubmit: submitQuery, className: 'border-2 p-2 px-3 flex items-center gap-2' },
            React.createElement("label", { htmlFor: "query", className: 'opacity-75' },
                React.createElement("img", { src: "/assets/images/icons/Search.svg", alt: "Input Query" })),
            React.createElement("input", { type: "text", name: "query", id: "query", defaultValue: props.query, placeholder: 'Search', className: 'bg-transparent' }),
            React.createElement("button", { type: 'submit' },
                React.createElement("img", { src: "/assets/images/icons/arrow-right.svg", alt: "Submit Query" })))));
};
