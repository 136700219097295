import React from "react";
import { createRoot } from "react-dom/client";
import { MessageArea } from "../components/filtering/MessageArea";
import { FilterFetch } from "./FilterFetch";
import { AjaxClient } from "../AjaxClient";
import { BreadCrumbsContainer } from "../components/filtering/BreadCrumbsContainer";
import { BreadCrumb } from "../components/filtering/BreadCrumb";
import { SubFilter } from "../components/filtering/SubFilter";
import { LoadingSpinner } from "../components/filtering/LoadingSpinner";
import { UseSearch, SearchForm } from "../components/filtering/TextSearch";
import { Paginator, ResultCount } from "../components/filtering/Paginator";
import { parseSearch, updateHistory } from "./history";
const AJAX_ENDPOINT = "/insights/grid-layout";
function prepState(objects, hierarchy, // which dropdown it belongs to
tagIds = []) {
    return objects.map(x => {
        return {
            active: tagIds.indexOf(x.term_id) >= 0 ? true : false,
            termId: x.term_id,
            name: x.name,
            hierarchy
        };
    });
}
function InsightsFilterBar(props) {
    const breadcrumbs = [
        ...props.categories,
        ...props.postTypes,
        ...props.postTags
    ].map(x => (React.createElement(BreadCrumb, { key: `${x.termId}-${x.name}`, termId: x.termId, name: x.name, active: x.active, handleClick: props.updateTerm, hierarchy: x.hierarchy })));
    const subFilterProps = [
        { label: "CATEGORIES", data: props.categories, hierarchy: "category" },
        {
            label: "POST TYPES",
            data: props.postTypes,
            hierarchy: "postType"
        },
        {
            label: "POST TAGS",
            data: props.postTags,
            hierarchy: "postTag"
        }
    ];
    const subFilters = subFilterProps.map(x => (React.createElement(SubFilter, { key: x.label, label: x.label, data: x.data, hierarchy: x.hierarchy, handleClick: props.updateTerm, clearDropdownHandler: props.clearDropdown, color: props.filterColor })));
    subFilters.push(React.createElement(UseSearch, { key: "useSearch", onClick: props.openSearch }));
    return (React.createElement("div", { id: "blog-card-filter-bar", className: "blog-card-filter-bar flex flex-col md:flex-row flex-wrap md:items-center relative col-span-5 gap-2 "
            + (props.filterColor === "white" ? "border-white" : "border-black") },
        props.showSearch ? React.createElement(SearchForm, { closeSearch: props.closeSearch, updateQuery: props.updateQuery, query: props.query }) : subFilters,
        React.createElement(BreadCrumbsContainer, { breadcrumbs: breadcrumbs }),
        React.createElement(MessageArea, { pending: props.pending, postCount: props.postCount })));
}
export class InsightsIndex extends React.Component {
    constructor(props) {
        super(props);
        this.blogLayoutRef = React.createRef();
        // Saving initial state so it's easy to wipe out a whole dropdown when you click "View All"
        this.initialState = {
            category: prepState(this.props.categoryData, "category", this.props.qParams.categories),
            postType: prepState(this.props.postTypeData, "postType", this.props.qParams.postTypes),
            postTag: prepState(this.props.postTagsData, "postTag", this.props.qParams.tags),
            pagination: this.props.paginationInit,
            page: this.props.qParams.page,
            pending: false,
            showSearch: this.props.qParams.query.length > 0 ? true : false,
            query: this.props.qParams.query,
            markup: this.props.initialMarkup,
            filterFetch: this.props.filterFetch
        };
        this.state = Object.assign({}, this.initialState);
        this.updateSingleTag = this.updateSingleTag.bind(this);
        this.updateShowSearch = this.updateShowSearch.bind(this);
        this.openSearch = this.openSearch.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.clearDropdown = this.clearDropdown.bind(this);
        this.fetchFilteredPosts = this.fetchFilteredPosts.bind(this);
        this.loadPage = this.loadPage.bind(this);
    }
    updateSingleTag(termId, active, hierarchy) {
        const newState = Object.assign({}, this.state);
        if (hierarchy in newState) {
            newState[hierarchy] = newState[hierarchy].map((x) => {
                if (x.termId === termId) {
                    return Object.assign(Object.assign({}, x), { active: !x.active });
                }
                else {
                    return x;
                }
            });
            newState.page = 1;
            this.setState(newState);
        }
    }
    updateShowSearch(showSearch) {
        const newState = Object.assign({}, this.state);
        newState.showSearch = showSearch;
        newState.query = "";
        newState.page = 1;
        this.setState(newState);
    }
    openSearch() {
        this.updateShowSearch(true);
    }
    closeSearch() {
        this.updateShowSearch(false);
    }
    updateQuery(q) {
        const newState = Object.assign({}, this.state);
        newState.query = q;
        newState.page = 1;
        this.setState(newState);
    }
    clearDropdown(hierarchy) {
        const newState = Object.assign({}, this.state);
        newState[hierarchy] = this.initialState[hierarchy];
        newState.page = 1;
        this.setState(newState);
    }
    buildFilters(filter, selection) {
        const activeSelection = selection
            .filter(x => x.active)
            .map(x => x.termId);
        return { group: filter, ids: activeSelection };
    }
    getActiveTerms(state) {
        let active = state.filter((term) => {
            return term.active;
        }).map((term) => {
            return term.termId;
        });
        return active;
    }
    fetchFilteredPosts(page = 1, method = "overwrite") {
        this.setState(Object.assign(Object.assign({}, this.state), { pending: true }));
        const historyState = {
            category: this.state.category,
            postType: this.state.postType,
            postTag: this.state.postTag,
            page,
            query: this.state.query
        };
        this.state.filterFetch.fetchPosts(historyState, AJAX_ENDPOINT).then((response) => {
            let postCount;
            const newState = Object.assign({}, this.state);
            const blogWrapper = this.blogLayoutRef.current;
            if (blogWrapper) {
                const markup = response.markup ? response.markup : "";
                if (method === "overwrite") {
                    newState.markup = markup;
                }
                else {
                    newState.markup = this.state.markup + markup;
                }
                newState.pending = false;
            }
            newState.pagination.totalPages = response.pages;
            newState.pagination.entryTotal = response.total;
            newState.pagination.first = response.first;
            newState.pagination.last = response.last;
            this.setState(newState);
        });
    }
    componentDidUpdate(prevProps, prevState) {
        // if query or filter changes
        if (!(this.state.category === prevState.category &&
            this.state.postType === prevState.postType &&
            this.state.postTag === prevState.postTag &&
            this.state.query === prevState.query)) {
            this.fetchFilteredPosts();
            const historyState = {
                category: this.state.category,
                postType: this.state.postType,
                postTag: this.state.postTag,
                page: this.state.page,
                query: this.state.query
            };
            updateHistory(historyState);
        }
        // if page number changes
        else if (!(this.state.page === prevState.page)) {
            this.fetchFilteredPosts(this.state.page);
            const historyState = {
                category: this.state.category,
                postType: this.state.postType,
                postTag: this.state.postTag,
                page: this.state.page,
                query: this.state.query
            };
            updateHistory(historyState);
        }
    }
    loadPage(pageNumber) {
        const newState = Object.assign({}, this.state);
        newState.page = pageNumber;
        this.setState(newState);
    }
    render() {
        const loadingStyles = this.state.pending ? " opacity-20" : "";
        return (React.createElement("div", { id: "InsightsIndex" },
            React.createElement("div", { className: "md:flex justify-between items-center border-bottom pb-7 mb-20 " + (this.props.filterColor === "white" ? "border-white" : "border-black") },
                React.createElement(InsightsFilterBar, { filterColor: this.props.filterColor, categories: this.state.category, postTypes: this.state.postType, postTags: this.state.postTag, updateTerm: this.updateSingleTag, clearDropdown: this.clearDropdown, postCount: this.state.postCount, pending: this.state.pending, showSearch: this.state.showSearch, openSearch: this.openSearch, closeSearch: this.closeSearch, updateQuery: this.updateQuery, query: this.state.query }),
                React.createElement(Paginator, { pagination: this.state.pagination, page: this.state.page, updatePage: this.loadPage })),
            React.createElement("div", { className: `insights-grid-container` + loadingStyles, ref: this.blogLayoutRef, dangerouslySetInnerHTML: { __html: this.state.markup } }),
            this.state.pending ?
                React.createElement(LoadingSpinner, null)
                :
                    React.createElement("div", { className: "border-t border-gray-350 mt-8 pt-8 md:flex justify-between" },
                        React.createElement(ResultCount, { className: "hidden md:block", pagination: this.state.pagination, page: this.state.page, updatePage: this.loadPage }),
                        React.createElement(Paginator, { pagination: this.state.pagination, page: this.state.page, updatePage: this.loadPage }))));
    }
}
export function onDOMLoaded() {
    const blogRoot = document.getElementById("insights-filterable-area-js");
    const blogWrapper = document.getElementById("blog-posts-wrapper");
    // @ts-ignore
    let data = window.insights;
    if (blogRoot && blogWrapper && data) {
        // get URL and see if there are any query params
        const qParams = parseSearch(document.location.search);
        const initialMarkup = blogWrapper.innerHTML;
        const filterFetch = new FilterFetch(new AjaxClient());
        const { color: filterColor, post_categories: categoryData, post_types: postTypeData, post_tags: postTagsData } = data.filters;
        const root = createRoot(blogRoot);
        root.render(React.createElement(InsightsIndex, { filterColor: filterColor, categoryData: categoryData, postTypeData: postTypeData, postTagsData: postTagsData, initialMarkup: initialMarkup, 
            // @ts-ignore paginationInit pulled from template
            paginationInit: paginationInit, qParams: qParams, filterFetch: filterFetch }));
    }
}
