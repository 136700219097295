import React from "react";
import { createRoot } from "react-dom/client";
import { MessageArea } from "../components/filtering/MessageArea";
import { BreadCrumbsContainer } from "../components/filtering/BreadCrumbsContainer";
import { FilterFetch } from "./FilterFetch";
import { AjaxClient } from "../AjaxClient";
import { BreadCrumb } from "../components/filtering/BreadCrumb";
import { SubFilter } from "../components/filtering/SubFilter";
import { parseSearch, updateHistory } from "./history";
const AJAX_ENDPOINT = "/case-studies/grid-layout";
// Industry == Legal | Medical | Regulatory etc.
const INDUSTRIES_CRAFT_HANDLE = "caseStudyIndustries";
// Service == Executive Consulting | Strategic Consulting | Computer Software, etc.
const SERVICES_CRAFT_HANDLE = "caseStudyServices";
// Technology == React | Python | AWS etc.
const TECHNOLOGY_CRAFT_HANDLE = "caseStudyTechnology";
function prepState(objects, hierarchy, // which dropdown it belongs to
tagIds = []) {
    return objects.map(x => {
        return {
            active: tagIds.indexOf(x.term_id) >= 0 ? true : false,
            termId: x.term_id,
            name: x.name,
            hierarchy
        };
    });
}
function CaseStudiesFilterBar(props) {
    const breadcrumbs = [
        ...props.industry,
        ...props.services,
        ...props.technology
    ].map(x => (React.createElement(BreadCrumb, { key: `${x.termId}-${x.name}`, termId: x.termId, name: x.name, active: x.active, handleClick: props.updateTerm, hierarchy: x.hierarchy })));
    const subfilterProps = [
        { label: "INDUSTRY", data: props.industry, hierarchy: "industry" },
        {
            label: "SERVICES",
            data: props.services,
            hierarchy: "services"
        },
        {
            label: "TECHNOLOGY",
            data: props.technology,
            hierarchy: "technology"
        }
    ];
    const subfilters = subfilterProps.map(x => (React.createElement(SubFilter, { key: x.label, label: x.label, data: x.data, hierarchy: x.hierarchy, handleClick: props.updateTerm, clearDropdownHandler: props.clearDropdown, color: props.filterColor })));
    return (React.createElement("div", { id: "case-studies-filter-bar", className: "case-studies-filter-bar border-bottom pb-7 mb-20 lg:flex lg:flex-wrap md:flex md:flex-wrap items-baseline relative "
            + (props.filterColor === "white" ? "border-white" : "border-black") },
        subfilters,
        React.createElement(BreadCrumbsContainer, { breadcrumbs: breadcrumbs }),
        React.createElement(MessageArea, { pending: props.pending, postCount: props.postCount })));
}
export class CaseStudiesIndex extends React.Component {
    constructor(props) {
        super(props);
        this.blogLayoutRef = React.createRef();
        this.initialState = {
            industry: prepState(this.props.industryData, "industry", this.props.qParams.industry),
            services: prepState(this.props.servicesData, "services", this.props.qParams.services),
            technology: prepState(this.props.technologyData, "technology", this.props.qParams.technology),
            pagination: this.props.paginationInit,
            page: this.props.qParams.page,
            pending: false,
            showSearch: this.props.qParams.query.length > 0 ? true : false,
            query: this.props.qParams.query,
            markup: this.props.initialMarkup,
            filterFetch: this.props.filterFetch
        };
        this.state = Object.assign({}, this.initialState);
        this.updateSingleTag = this.updateSingleTag.bind(this);
        this.clearDropdown = this.clearDropdown.bind(this);
        this.fetchFilteredPosts = this.fetchFilteredPosts.bind(this);
    }
    updateSingleTag(termId, active, hierarchy) {
        const newState = Object.assign({}, this.state);
        if (hierarchy in newState) {
            newState[hierarchy] = newState[hierarchy].map((x) => {
                if (x.termId === termId) {
                    return Object.assign(Object.assign({}, x), { active: !x.active });
                }
                else {
                    return x;
                }
            });
            this.setState(newState);
        }
    }
    clearDropdown(hierarchy) {
        const newState = Object.assign({}, this.state);
        newState[hierarchy] = this.initialState[hierarchy];
        this.setState(newState);
    }
    buildFilters(filter, selection) {
        const activeSelection = selection
            .filter(x => x.active)
            .map(x => x.termId);
        return { group: filter, ids: activeSelection };
    }
    fetchFilteredPosts(options = { method: "overwrite" }) {
        this.setState(Object.assign(Object.assign({}, this.state), { pending: true }));
        const historyState = {
            industry: this.state.industry,
            services: this.state.services,
            technology: this.state.technology,
            page: 0
        };
        this.state.filterFetch.fetchPosts(historyState, AJAX_ENDPOINT)
            .then((response) => {
            const newState = Object.assign({}, this.state);
            const blogWrapper = this.blogLayoutRef.current;
            if (blogWrapper) {
                const markup = response.markup ? response.markup : "";
                if (options.method === "overwrite") {
                    newState.markup = markup;
                }
                else {
                    newState.markup = this.state.markup + markup;
                }
                newState.pending = false;
            }
            this.setState(newState, () => {
                const parent = document.querySelector(".case_studies__list");
                const children = parent.querySelectorAll("div");
                this.setState({ postCount: children.length });
            });
        })
            .catch(error => console.error(error));
    }
    componentDidUpdate(prevProps, prevState) {
        if (!(this.state.services === prevState.services &&
            this.state.technology === prevState.technology &&
            this.state.industry === prevState.industry)) {
            this.fetchFilteredPosts();
            const historyState = {
                industry: this.state.industry,
                services: this.state.services,
                technology: this.state.technology,
                page: 0
            };
            updateHistory(historyState);
        }
    }
    render() {
        const loadingStyles = this.state.pending ? " opacity-20" : "";
        return (React.createElement("div", null,
            React.createElement(CaseStudiesFilterBar, { filterColor: this.props.filterColor, industry: this.state.industry.map(item => (Object.assign(Object.assign({}, item), { name: item.name.replace(/&amp;/g, "&") }))), services: this.state.services.map(item => (Object.assign(Object.assign({}, item), { name: item.name.replace(/&amp;/g, "&") }))), technology: this.state.technology, updateTerm: this.updateSingleTag, clearDropdown: this.clearDropdown, postCount: this.state.postCount, pending: this.state.pending }),
            React.createElement("div", { className: `case-studies-grid` + loadingStyles, ref: this.blogLayoutRef, dangerouslySetInnerHTML: { __html: this.state.markup } })));
    }
}
export function onDOMLoaded() {
    const blogRoot = document.getElementById("case-studies-filterable-area-js");
    const blogWrapper = document.getElementById("case-studies-wrapper");
    // @ts-ignore
    let data = window.insights;
    if (blogRoot && blogWrapper && data) {
        // get URL and see if there are any query params
        const qParams = parseSearch(document.location.search);
        const initialMarkup = blogWrapper.innerHTML;
        const postCount = blogWrapper.childElementCount;
        const { color: filterColor, case_study_services: servicesData, case_study_technology: technologyData, case_study_industry: industryData } = data.filters;
        const root = createRoot(blogRoot);
        root.render(React.createElement(CaseStudiesIndex, { filterColor: filterColor, servicesData: servicesData, technologyData: technologyData, industryData: industryData, initialMarkup: initialMarkup, filterFetch: new FilterFetch(new AjaxClient()), qParams: qParams, 
            // @ts-ignore paginationInit pulled from template
            paginationInit: paginationInit }));
    }
}
