var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Methods } from "../AjaxClient";
import { writeQueryFromKeys } from "./history";
export class FilterFetch {
    constructor(client) {
        this.client = client;
    }
    fetchPosts(state, endpoint) {
        return __awaiter(this, void 0, void 0, function* () {
            const getQuery = writeQueryFromKeys(state);
            const reqURL = endpoint + "?" + getQuery;
            return this.client.makeRequest(Methods.GET, reqURL.toString(), { "Content-Type": "application/json" }).then((response) => __awaiter(this, void 0, void 0, function* () {
                if (response.status === 200) {
                    return yield this.handleSuccessfulResponse(response);
                }
                else {
                    return this.handleErrorResponse(response);
                }
            }))
                .catch(error => {
                console.error(error);
                return { count: 0, total: 0 };
            });
        });
    }
    handleErrorResponse(response) {
        response.text().then((error) => console.error(error));
        return { markup: "", total: 0, count: 0 };
    }
    handleSuccessfulResponse(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const count = Number(response.headers.get("Post-Count"));
            const total = Number(response.headers.get("Entry-Total"));
            const pages = Number(response.headers.get("Total-Pages"));
            const current = Number(response.headers.get("Current-Page"));
            const first = Number(response.headers.get("First-Entry"));
            const last = Number(response.headers.get("Last-Entry"));
            const responseText = yield response.text();
            const text = responseText.trim();
            return { markup: text, total: total, count: count, pages, current, first, last };
        });
    }
}
